import React, { useContext, useEffect } from "react";
import { graphql, Link } from "gatsby";

import styles from "./home.module.css";
import Layout from "../components/Layout";
import ProductPreview from "../components/ProductPreview";

import {
  OrderStateContext,
  OrderDispatchContext,
} from "../context/order-context";

import { usePageTracking } from "../utils/usePageTracking";

function HomePage({ data }) {
  // There is only one list of products that we care
  const { products } = data.products.nodes[0];

  const dispatch = useContext(OrderDispatchContext);
  const { lastAdded } = useContext(OrderStateContext);

  usePageTracking();

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch({ type: "RESET_LAST_ADDED" });
    }, 7000);

    return () => clearInterval(timer);
  }, [dispatch, lastAdded]);

  return (
    <Layout location="Proizvodi — Zaštita i popravak knjiga">
      <main>
        <ul className={styles.productList}>
          {products.map((product) => {
            let images = [product.image.fluid];
            if (product.image2) {
              images.push(product.image2.fluid);
            }

            return (
              <li key={product.id}>
                <ProductPreview
                  productId={product.id}
                  name={product.name}
                  images={images}
                  description={product.description.json}
                  dimensions={product.dimensions}
                />
              </li>
            );
          })}
        </ul>

        {lastAdded && (
          <div className={styles.notification}>
            <span>
              <b>Dodano u upit za ponudu!</b>
              {lastAdded.qty} x {lastAdded.name}
            </span>
            <Link to="/shopping-cart">Upit za ponudu <span role="img" aria-label="list">📝</span></Link> 
          </div>
        )}
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query HomeQuery {
    products: allContentfulProductsList {
      nodes {
        name
        products {
          ... on ContentfulProduct {
            id
            name
            dimensions {
              code
              label
              price
            }
            description {
              json
            }
            image {
              fluid(maxWidth: 568) {
                sizes
                src
                srcSet
                aspectRatio
              }
            }
            image2 {
              fluid(maxWidth: 568) {
                sizes
                src
                srcSet
                aspectRatio
              }
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
