import React, { useContext, useState } from "react";
import Img from "gatsby-image";
import cn from "classnames";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import styles from "./ProductPreview.module.css";
import { formatNumberToHrk, formatNumberToEur } from "../utils/formatText";
import { OrderDispatchContext } from "../context/order-context";

function ProductPreview({ productId, name, images, description, dimensions }) {
  const [qty, setQty] = useState(1);
  const [selected, setSelected] = useState(dimensions[0]);
  const [qtyError, setQtyError] = useState();
  const [activeImg, setActiveImg] = useState(0);

  const dispatch = useContext(OrderDispatchContext);

  const handleAddToOrder = () => {
    const payload = {
      name,
      image: images[0],
      code: selected.code,
      dimension: selected.label,
      price: Number(selected.price),
      qty,
    };

    setQtyError(null);
    dispatch({
      type: "ADD_PRODUCT",
      payload,
    });
  };

  const handleChangeDimension = (evt) => {
    if (evt.target.value !== selected.code) {
      const dimension = dimensions.find(
        (dimension) => dimension.code === evt.target.value
      );
      setSelected(dimension);
    }
  };

  const handleChangeQty = (evt) => {
    const value = evt.target.value.replace(/\D/g, "");
    setQty(Number(value !== "" ? value : 0));
  };

  return (
    <article className={styles.product}>
      <div className={styles.imageCol}>
        {images.map((image, idx) => (
          <Img
            key={idx}
            className={cn(styles.productImg, {
              [styles.show]: activeImg === idx,
            })}
            alt=""
            fluid={image}
          />
        ))}
        {images.length > 1 && (
          <button
            className={styles.imageLink}
            onClick={() => setActiveImg(activeImg === 0 ? 1 : 0)}
          >
            Vidi sliku {activeImg === 0 ? "2" : "1"} →
          </button>
        )}
      </div>
      <span className={styles.productInfo}>
        <h3>{name}</h3>
        <span className={styles.description}>
          {documentToReactComponents(description)}
        </span>
        <form id={productId}>
          <label>
            Dimenzije
            <div className="selectContainer">
              <select
                id="product"
                name="product"
                onChange={handleChangeDimension}
                // we need onBlur for a11y
                onBlur={handleChangeDimension}
              >
                {dimensions.map((dimension, idx) => {
                  return (
                    <option key={idx} value={dimension.code}>
                      {dimension.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </label>
          <label>
            Količina
            <input
              id="quantity"
              name="quantity"
              type="tel"
              value={qty}
              onChange={handleChangeQty}
            />
          </label>
          <span className={styles.price}>
            <b>Cijena</b>
            <p>{formatNumberToEur(selected.price * qty / 7.53450)}</p>
          </span>
          <button type="button" onClick={handleAddToOrder}>
            Dodaj proizvod
          </button>
          {qtyError && <span className={styles.error}>{qtyError}</span>}
        </form>
      </span>
    </article>
  );
}

export default ProductPreview;
